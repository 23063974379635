import InterviewLayout from "../../../layouts/InterviewLayout/InterviewLayout";
import InterviewQuestion from "../../../components/InterviewQuestion/InterviewQuestion";
import ImageRect from "../../../components/ImageRect/ImageRect";
import BrandSheet from "../../../components/BrandSheet/BrandSheet";
import BrandSheetRow from "../../../components/BrandSheetRow/BrandSheetRow";
import InterviewCredits from "../../../components/InterviewCredits/InterviewCredits";

export default function Officinae() {
  return (
    <>
      <InterviewLayout>
        <h1 className="text-4xl md:text-7xl text-mib-brown-dark font-crimson-text font-semibold leading-[.95em] tracking-tight">
          Officinae
        </h1>
        <BrandSheet>
          <BrandSheetRow title="Anno di fondazione">2010</BrandSheetRow>
          <BrandSheetRow title="Fondatrice e designer">
            Paola Tirinzoni
          </BrandSheetRow>
          <BrandSheetRow title="Collezioni">
            Due all'anno - Primavera/Estate e Autunno/Inverno, <br />
            Donna
          </BrandSheetRow>
          <BrandSheetRow title="Target">
            Donne dai 40/45 anni in su con un forte interesse verso il fatto a
            mano di varie parti del mondo
          </BrandSheetRow>
          <BrandSheetRow title="Valori">
            Artigianalità <br />
            Territorialità <br />
            Sostenibilità <br />
            Cura, attenzione e ascolto verso i clienti
          </BrandSheetRow>
          <BrandSheetRow title="Produzione">
            Albano Sant'Alessandro e Seriate, Provincia di Bergamo
          </BrandSheetRow>
          <BrandSheetRow title="Materiali utilizzati">
            Cotone, lino, lana, seta, taffetà
          </BrandSheetRow>
          <BrandSheetRow title="Tratti distintivi">
            Realtà famigliare; <br />
            Lavorazione di ogni capo artigianalmente; <br />
            Possibilità di modificare misure e tessuti; <br />
            La maggior parte dei tessuti vengono da deadstock e una piccola
            parte da nuovo; <br />
            Creazioni di abiti da cerimonia interamente su misura; <br />
            Tecniche tramandate di generazione in generazione.
          </BrandSheetRow>
          <BrandSheetRow title="Negozio">
            Via T.Tasso 32 - Bergamo
          </BrandSheetRow>
          <BrandSheetRow title="Contatti">
            Sito web: www.officinaelab.com <br /> Instagram: @officinae_ <br />
            Facebook: Officinae <br /> Mail: info@officinaelab.com <br /> Tel:
            +39 035 210 643 <br /> Cell.: +36 338 181 5859
          </BrandSheetRow>
        </BrandSheet>
        <InterviewCredits
          content="Intervista con Tommaso del brand Officinae di Paola Tirinzoni"
        />
        <InterviewQuestion question="Quando è nato Officinae, come lavora e quali sono i suoi valori?">
          «Officinae è un brand che nasce nel 2010 per mano della titolare e
          stilista Paola Tirinzoni ed è una realtà a conduzione famigliare
          composta da una ventina di persone. Esso prende vita con l’idea di
          tramandare dei valori di artigianalità e di tradizione che altrimenti
          crediamo possano andare persi, scegliendo di continuare a creare i
          capi tagliando e cucendo i pezzi di tessuto uno ad uno, un metodo di
          lavoro ad oggi molto raro nel settore moda. Miriamo a produrre senza
          creare sovrapproduzioni, facendo dei capi solo dove realmente c’è una
          necessità, infatti l’obiettivo del marchio non è quello di raggiungere
          fatturati enormi e altrettanti profitti ma è quello di custodire e
          salvaguardare il valore artigianale, ovviamente con una percentuale di
          guadagno per il brand e per i suoi lavoratori».
        </InterviewQuestion>
        <InterviewQuestion question="Perché avete così a cuore l’artigianalità?">
          «Pensiamo che ad oggi ci sia ancora poca conoscenza del reale valore
          artigianale di un prodotto e che ci sia più interesse da parte di un
          target di una certa età, questo è certamente influenzato anche dal
          punto di vista economico in quanto i prodotti fatti a mano, con
          filiera corta e quasi su richiesta hanno un costo non paragonabile
          alla produzione industriale, però essi hanno un valore intrinseco
          totalmente diverso. C’è un grande interesse da parte della generazione
          giovane dai vent’anni in su ma è ancora un’interesse più mediatico che
          concreto, per esempio c’è tanta attenzione anche alla sostenibilità,
          un valore dell’artigianalità in quanto ciò che è fatto per una persona
          con materiali di qualità e con grande sapienza è un prodotto pensato
          per durare. Possiamo dire che non c’è ancora una cultura totale di
          investimento in un prodotto come questo, ma c’è più una volontà di
          acquistare prodotti in grandi quantità per dare più una
          rappresentazione della propria persona e per fare questo lo si può
          fare solo con una grande disponibilità economica o con dei brand fast
          fashion».
        </InterviewQuestion>
        <InterviewQuestion question="Com’è organizzata la vostra produzione?">
          «Il nostro atelier dove avviene la produzione dei capi si trova ad
          Albano Sant’Alessandro in provincia di Bergamo. Per quanto riguarda i
          capi in tessuto, qui Luisa e tutte le sue collaboratrici tagliano e
          confezionano uno ad uno ogni creazione.
          <ImageRect
            className="col-span-4 md:col-span-7"
            imageUrl="../assets/images/webp/interviews/officinae/2.webp"
            dida=""
          />
          <ImageRect
            className="col-span-4 md:col-span-7"
            imageUrl="../assets/images/webp/interviews/officinae/3.webp"
            dida=""
          />
          <ImageRect
            className="col-span-4 md:col-span-7"
            imageUrl="../assets/images/webp/interviews/officinae/4.webp"
            dida=""
          />
          Mentre per la maglieria collaboriamo da anni con un maglificio a
          conduzione famigliare a Seriate, sempre in provincia di Bergamo, dove
          tutta la produzione viene fatta internamente da sei persone.
          <ImageRect
            className="col-span-4 md:col-span-7"
            imageUrl="../assets/images/webp/interviews/officinae/5.webp"
            dida=""
          />
          <ImageRect
            className="col-span-4 md:col-span-7"
            imageUrl="../assets/images/webp/interviews/officinae/6.webp"
            dida=""
          />
          Ogni maglia viene creata una per uno, ci sono pochissime macchine ed
          ogni persona si dedica ad una maglia. Questo per noi è un grande
          valore perché significa prendersi cura del prodotto nei minimi
          dettagli e a differenza delle macchine industriali, le quali mirano a
          produrre il maggior numero di capi nel minor tempo possibile, le
          nostre sono antiche, funzionano a mano con una persona che crea i nodi
          per la maglieria. La possibilità di lavorare ogni capo singolarmente
          fa si che a volte si possano soddisfare delle richieste particolari da
          parte delle clienti, sempre mantenendo lo stile di Officinae e dando
          la possibilità di cambiare il tessuto o le misure, realizzando un capo
          su misura a tutti gli effetti. Realizziamo anche vari accessori come
          per esempio le sciarpe patch, un prodotto iconico del brand, i quali
          vengono realizzati a mano con l’uncinetto dalla stilista Paola ed
          inoltre creiamo anche abiti da cerimonia interamente su misura».
          <ImageRect
            className="col-span-4 md:col-span-7"
            imageUrl="../assets/images/webp/interviews/officinae/7.webp"
            dida=""
          />
          <ImageRect
            className="col-span-4 md:col-span-7"
            imageUrl="../assets/images/webp/interviews/officinae/8.webp"
            dida=""
          />
          <ImageRect
            className="col-span-4 md:col-span-7"
            imageUrl="../assets/images/webp/interviews/officinae/9.webp"
            dida=""
          />
        </InterviewQuestion>
        <InterviewQuestion question="Da dove nasce la passione di Paola e come ha appreso queste tecniche di produzione artigianali?">
          «La passione di Paola verso il mondo della moda nasce quando era molto
          piccola grazie alla sua famiglia in cui tutti, con diverse abilità e
          mansioni, hanno sempre lavorato in questo settore. Paola all’età di
          tre anni aveva già i ferri da maglia in mano e tutto ciò che ha
          imparato le è stato tramandato da sua nonna Elda e da sua mamma
          Liliana, la quale collabora tuttora nella creazione di alcuni capi di
          Officinae. Liliana si occupa maggiormente della creazione del
          packaging di vendita dei prodotti, realizzato con gli scarti di
          tessuto dei capi. Questo è un aspetto di sostenibilità che abbiamo
          ideato. Vengono utilizzate delle veline (prodotte sempre a Bergamo)
          con un tema floreale all’interno delle quali viene avvolto il capo,
          inserito poi nelle tote bag realizzate da Liliana, evitando così lo
          spreco e l’utilizzo di borse di plastica».
        </InterviewQuestion>
        <InterviewQuestion question="Quali materiali vengono utilizzati per la produzione dei capi e da dove provengono?">
          «La maggior parte delle volte i materiali che acquistiamo provengono
          da scarti di produzione di aziende tessili, in primis per evitare che
          vengano gettati e che vadano così ad inquinare e per far sì che i
          nostri prezzi non siano troppo alti. Questo significa anche acquistare
          tessuti difettosi ma pur sempre di alta qualità e andando a lavorare a
          mano possiamo evitare i difetti grazie alle forbici. Inoltre, fin
          dalla fondazione del brand, prestiamo molta attenzione agli sprechi e
          miriamo a non gettare nulla. Oltre ai tessuti deadstock utilizziamo
          anche una parte di tessuti nuovi, come il taffetà che è composto al
          100% da poliestere rigenerato, consapevoli che deriva dal petrolio
          stiamo maggiormente attenti nella scelta, lavorando con aziende che
          cercano materiali con certificazioni ma soprattutto cerchiamo di
          comprarne di alta qualità, per permettere una longevità di utilizzo
          del prodotto che non tutti i tessuti sintetici hanno e questo
          rappresenta un altro dei valori dei nostri capi. Noi non scegliamo il
          taffetà per sostenibilità economica, in quanto è uno tra i più costosi
          che utilizziamo, ma perché ha delle performance che altri tessuti non
          hanno, ad esempio può giocare con la luce e prendere un colore
          diverso, oltre ad altre caratteristiche tattili. La maggior parte
          delle nostre creazioni come i pantaloni, le camicie, le giacche e le
          gonne, sono però realizzate con fibre naturali come il cotone, il
          lino, la lana e la seta».
          <ImageRect
            className="col-span-4 md:col-span-7"
            imageUrl="../assets/images/webp/interviews/officinae/10.webp"
            dida=""
          />
        </InterviewQuestion>
        <InterviewQuestion question="Quante collezioni create all’anno e quali sono i ritmi di vendita?">
          «Due collezioni all’anno, primavera/estate e autunno/inverno, questo
          perché ci piace rimanere legati a quello che è sempre stato il ciclo
          delle stagioni in passato, non ci siamo adattati al creare
          micro-collezioni durante l’anno perché abbiamo un business model che
          ci permette di sfruttare il nostro magazzino attraverso il negozio e
          le storie di Instagram. Abbiamo un libro tessuti dove mettiamo tutti i
          tessuti, vecchi e nuovi, che compongono le collezioni, catalogati in
          modo da sapere sempre tutto quello che abbiamo in magazzino in modo
          tale da avere la possibilità di creare sempre nuovi capi e avere una
          collezione di tessuti in costante evoluzione. Abbiamo una collezione
          principale in vendita sul nostro sito web e in negozio, e ogni dieci
          giorni in quest’ultimo vengono proposti nuovi capi. Le due collezioni
          non vengono presentate sei mesi prima e messe in vendita sei mesi dopo
          ma passano solamente due settimane circa. Avendo tessuti che sono
          reperibili facilmente noi li scegliamo sapendo quanta disponibilità
          abbiamo per ciascuno, facciamo il campionario, le fotografie, lo
          portiamo in negozio e da lì riceviamo i primi ordini».
        </InterviewQuestion>
        <InterviewQuestion question="Qual’è il vostro target?">
          «Il nostro target è composto da signore di età tra i
          quaranta/quarantacinque anni in su con interessi principalmente nel
          mondo della botanica, dei fiori, dell’artigianato e del Made in Italy,
          a cui piace molto la cura della casa e i mercatini vintage di nicchia.
          Il target cambia un pò in base all’online e al negozio fisico, in
          negozio tante clienti non hanno tutti questi interessi ma apprezzano
          il fatto a mano e le sue caratteristiche ed è un target più ampio
          rispetto a quello dell’online. Il brand viene apprezzato non solo in
          Italia ma anche all’estero da tante donne provenienti da Inghilterra,
          Spagna, Francia, Stati Uniti, Australia e altri Stati».
        </InterviewQuestion>
        <InterviewQuestion question="Quali sono i canali di vendita che utilizzate?">
          «I nostri capi vengono venduti sul sito web, nel negozio in via in via
          Torquato Tasso a Bergamo e in altri negozi rivenditori situati in
          Galles, a sud di Londra, a Padova e in Cina. In Galles vendiamo in tre
          negozi da circa dieci anni, a Londra sempre in tre negozi da qualche
          mese, a Padova in uno da cinque anni e anche in Cina in uno ma da
          quasi un anno. Il nostro negozio a Bergamo ad oggi è ancora il canale
          di vendita maggiormente utilizzato. I capi vengono proposti in taglie
          standard che possono essere anche adattate in base alle misure delle
          clienti, con una taglia personalizzata pagando un piccolo supplemento
          per coprire le spese del tessuto e del nuovo cartamodello creato
          appositamente. Una cosa che contraddistingue il brand sia a livello
          fisico che online è la capacità di ascoltare la persona, per noi è un
          grande valore in quanto le nostre clienti si affidano a noi e sanno di
          essere ascoltate».
          <ImageRect
            className="col-span-4 md:col-span-7"
            imageUrl="../assets/images/webp/interviews/officinae/14.webp"
            dida=""
          />
          <ImageRect
            className="col-span-4 md:col-span-7"
            imageUrl="../assets/images/webp/interviews/officinae/15.webp"
            dida=""
          />
        </InterviewQuestion>
        <InterviewQuestion question="Per la comunicazione e promozione cosa utilizzate?">
          «Principalmente ci promuoviamo con Instagram e con la nostra
          newsletter ma pubblichiamo anche su Facebook, nonostante ci sia
          un’audience minore a differenza di Instagram. Inoltre utilizziamo
          anche Whatsapp e il nostro sito web».
        </InterviewQuestion>
      </InterviewLayout>
    </>
  );
}
